.countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: monospace;
}

.time-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fade-card {
  width: 24px;
  height: 24px;
  position: relative;
  /* margin-bottom: 10px; */
}

.fade-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.fade-card-front,
.fade-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: grey;
  opacity: 1;
  transition: opacity 3s ease-in-out;
}

.fade-card-front {
  background-color: white;
}

.fade-card-back {
  background-color: white;
}

/* Fade-out and fade-in transitions */
.fade-out {
  opacity: 0;
}

.fade-in {
  opacity: 1;
}

span.timer {
  font-size: 12px;
  color: grey;
}