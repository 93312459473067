.App {
  text-align: left;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* flex: 1;
  height: 90%;
  width: 90%;
  border: solid 2px blue; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jspsych-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  opacity: 1;
  padding: 6px 8px;
  margin: 8px;
  font-size: 16px;
  border: 2px;
  border-radius: 4px;
  border-style: solid;
  border-color: #1976d2;
  color: #1976d2;
  cursor: pointer;
  user-select: none;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  background-color: transparent;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  /* box-shadow: 0 0 10px 40px lightgrey; */
}

.jspsych-btn:disabled {
  /* display: none; */
  visibility: hidden;
  opacity: 0;
}

.jspsych-btn:hover {
  transition-duration: 1s;
  background-color: #1976d2;
  color: #ffffff;
}

.jspsych-btn:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 4px;
  left: 0;
  top:0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s;
  box-shadow: 0 0 10px 40px grey;
}

.jspsych-btn:active:after {
  box-shadow: 0 0 0 0 grey;
  position: absolute;
  border-radius: 4px;
  left: 0;
  top:0;
  opacity: 1;
  transition: 0s;
}

.jspsych-btn:active {
  top: 1px;
}

.jspsych-content {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  color: #0d47a1;
  font-size: larger;
  margin: 12px;
}

.jspsych-content select {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  font-size: 16px!important;
  color: #1976d2!important;
  padding: 8px 12px!important;
  border: 1px;
  border-radius: 4px;
  border-style: solid;
  border-color: #1976d2;
}

.jspsych-content select option {
  font-size: 16px!important;
  color: #1976d2!important;
  padding: 8px 12px!important;
}

.sv-root-modern {
  width: 100%;
}

div#jspsych-target {
  /* background-color: orange; */
  background: none;
  transform: translateY(-3vmin);
  /* transform: translateY(clamp(-5vmin, -32px, -2vmin)); */
}

div#jspsych-target:focus {
  outline: none;
}

div.jspsych-content video {
  object-fit: fill;
}

div.jspsych-content img {
  object-fit: fill;
}

.auto-max {
  max-width: calc(85vmin - 64px);
  max-height: calc(85vmin - 64px);
}



div.jspsych-survey-text-question input, div.jspsych-survey-text-question textarea {
  font-size: 1.2rem;
  text-align: center;
  padding: 8px;
  font-family: Roboto, 'sans-serif';
}

p.jspsych-survey-text {
  font-size: 1.8rem;
}

p.dold-media-prompt {
  font-size: 2.4rem;
  /* color: orangered; */
}